import * as Yup from 'yup';

import {
  confirmedPasswordSchema,
  emailSchema,
  passwordSchema,
  referralCodeSchema,
  usernameSchema,
} from '@shared/schemas';

export const registerSchema = emailSchema
  .concat(usernameSchema)
  .concat(passwordSchema)
  .concat(confirmedPasswordSchema)
  .concat(Yup.object({ enableEmailNotifications: Yup.boolean() }));

export type RegisterFormData = Yup.InferType<typeof registerSchema>;

export const registerUserSchema = registerSchema.concat(referralCodeSchema)

export type RegisterUserFormData = Yup.InferType<typeof registerUserSchema>;


export const registerWithGoogleSchema = usernameSchema.concat(referralCodeSchema)
export type RegisterWithGGFormData = Yup.InferType<typeof registerWithGoogleSchema>;
