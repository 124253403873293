import { InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import PasswordInput from './PasswordInput';
import PasswordValidation from './PasswordValidation';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  register: Partial<UseFormRegisterReturn>;
  id: string;
  error?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  autoresize?: boolean;
}

const PasswordInputWithValidation = ({
  label,
  id,
  register,
  error,
  required,
  className,
  disabled,
  autoresize,
  maxLength,
}: IProps) => {
  return (
    <div className="flex flex-col gap-4">
      <PasswordInput
        label={label}
        autoResize={autoresize}
        id={id}
        register={register}
        error={error}
        className={className}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
      />
      <PasswordValidation id={id} />
    </div>
  );
};

export default PasswordInputWithValidation;
