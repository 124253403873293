import {
  AT_LEAST_ONE_SPECIAL_CHARACTER,
  AT_LEAST_ONE_UPPERCASE_LOWERCASE_DIGIT,
  MIN_8_CHARACTERS,
} from '@shared/utils/regex';

import { PASSWORD_VALIDATION_MESSAGES } from './validatioMessage';

export const PASSWORD_VALIDATION = [
  {
    label: PASSWORD_VALIDATION_MESSAGES.UPPERCASES_LOWERCASES_DIGITS_NO,
    regex: AT_LEAST_ONE_UPPERCASE_LOWERCASE_DIGIT,
  },
  {
    label: PASSWORD_VALIDATION_MESSAGES.SPECIAL_CHARACTERS_NO,
    regex: AT_LEAST_ONE_SPECIAL_CHARACTER,
  },
  {
    label: PASSWORD_VALIDATION_MESSAGES.MIN_LENGTH,
    regex: MIN_8_CHARACTERS,
  },
];
