import {
  AuthForgotData,
  AuthResetPasswordResponseData,
  ErrorResponse,
} from '@interfaces';
import { useMutation } from '@tanstack/react-query';
import { userAuthApi } from '../../userAuthApi';


interface MutationParams {
  onSuccess: (response: AuthResetPasswordResponseData) => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useUserForgotMutation = ({ onSuccess, onError }: MutationParams) => {
  return useMutation({
    mutationFn: async (data: AuthForgotData) =>
      userAuthApi.client.userForgotPassword(data),
    onSuccess,
    onError,
  });
};

export default useUserForgotMutation;
