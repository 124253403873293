import { useFormContext } from 'react-hook-form';

import { CheckIcon2, CloseIcon } from '@shared/assets';

import { PASSWORD_VALIDATION } from './constants';

interface IProps {
  id: string;
}

const PasswordValidation = ({ id }: IProps) => {
  const { watch } = useFormContext();
  const passwordValue = watch(id);

  return (
    <div className="flex flex-col gap-2">
      {PASSWORD_VALIDATION.map((item) => (
        <div key={item.label} className="flex items-center gap-2">
          {passwordValue && item.regex.test(passwordValue) ? (
            <CheckIcon2 className="text-turquoise500 h-4 w-4" />
          ) : (
            <CloseIcon className="h-4 w-4 text-red500" />
          )}
          <p className="font-small text-neutral200">{item.label}</p>
        </div>
      ))}
    </div>
  );
};

export default PasswordValidation;
