import { InputHTMLAttributes, useRef, useState } from 'react';
import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import { twMerge } from 'tailwind-merge';

import { EyeOpen, Hidden } from '@shared/assets';

import { DEFAULT_INPUT_MIN_WIDTH } from '../hookFormInput/constants';
import useAutoResize from '../hooks/useAutoResize';
import InputWrapper from '../inputWrapper';
import InputStyle from '../styles/inputStyle';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  register: Partial<UseFormRegisterReturn>;
  label?: string;
  error?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  autoResize?: boolean;
  hiddenSpanClassName?: string;
  minWidth?: number;
  linkText?: string;
  linkHref?: string;
  tooltip?: string;
  hideIsOptional?: boolean;
  buttonText?: string;
  onButtonTextClick?: () => void;
}

const PasswordInput = ({
  id,
  register,
  label,
  error,
  required,
  className,
  disabled,
  autoResize,
  hiddenSpanClassName,
  linkText,
  linkHref,
  minWidth = DEFAULT_INPUT_MIN_WIDTH,
  tooltip,
  hideIsOptional,
  buttonText,
  onButtonTextClick,
  ...attributes
}: IProps) => {
  const { watch } = useFormContext();
  const value = watch(id);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handlePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const spanAutoResizeRef = useRef<HTMLSpanElement>(null);
  const divContainerRef = useRef<HTMLDivElement>(null);

  const getInputStyle = useAutoResize({
    value,
    spanAutoResizeRef,
    divContainerRef,
    minWidth,
    customRightPaddingOffset: 50,
    isPasswordInput: true,
    autoResize,
    isPasswordVisible,
  });

  return (
    <div ref={divContainerRef}>
      <InputWrapper
        label={label}
        id={id}
        error={error}
        required={required}
        hideIsOptional={hideIsOptional}
        className={className}
        linkText={linkText}
        linkHref={linkHref}
        tooltip={tooltip}
        tooltipClassName="max-sm:max-w-72 max-sm:w-screen max-sm:left-0"
        buttonText={buttonText}
        onButtonClick={onButtonTextClick}
      >
        <div className="relative">
          <div className="w-fit">
            <input
              {...register}
              {...attributes}
              id={id}
              type={isPasswordVisible ? 'text' : 'password'}
              aria-invalid={!!error}
              autoComplete="current-password webauthn"
              disabled={disabled}
              className={InputStyle(error)}
              style={getInputStyle()}
            />
            {autoResize && (
              <span
                className={twMerge(
                  'font-xsmall invisible fixed px-1.5 pl-3 font-bold',
                  hiddenSpanClassName
                )}
                ref={spanAutoResizeRef}
              >
                {value}
              </span>
            )}
          </div>
          <button
            type="button"
            className="absolute right-0 top-1.5 z-10 cursor-pointer opacity-100"
            onClick={handlePasswordVisibility}
          >
            {isPasswordVisible ? (
              <Hidden className="text-neutral300" />
            ) : (
              <EyeOpen className="text-neutral300" />
            )}
          </button>
        </div>
      </InputWrapper>
    </div>
  );
};

export default PasswordInput;
