import {
  ErrorResponse,
  UserLoginResponse,
  UserLoginData,
} from '@interfaces';
import { useMutation } from '@tanstack/react-query';
import { userAuthApi } from '../../userAuthApi';


interface MutationParams {
  onSuccess: (response: UserLoginResponse) => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useUserLoginMutation = ({ onSuccess, onError }: MutationParams) => {
  return useMutation({
    mutationFn: async (data: UserLoginData) =>
      userAuthApi.client.login(data),
    onSuccess,
    onError,
  });
};

export default useUserLoginMutation;
