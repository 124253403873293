import * as Yup from 'yup';

import { PASSWORD_REGEX } from '@components/form/passwordInput/regex';

import {
  MAX_EMAIL_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_USERNAME_LENGTH,
} from '@shared/constants';
import {
  EMAIL_REGEX,
  INTEGER_REGEX,
  USERNAME_REGEX,
} from '@shared/utils/regex';

export const confirmedPasswordSchema = Yup.object({
  confirmedPassword: Yup.string()
    .trim()
    .required('Please enter your password')
    .max(MAX_PASSWORD_LENGTH)
    .test(
      'password-confirmed',
      'The new password and confirm password you entered do not match. Please try again',
      (value, context) => value === context.parent.password
    ),
});

export const emailSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required('Please enter an email address')
    .max(MAX_EMAIL_LENGTH)
    .matches(EMAIL_REGEX, 'Please enter a valid email address'),
});

export type EmailFormData = Yup.InferType<typeof emailSchema>;

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Please enter your password')
    .max(MAX_PASSWORD_LENGTH)
    .matches(PASSWORD_REGEX, 'Please enter a valid password'),
});

export const passwordWithValidationBlockSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Please enter your password')
    .max(MAX_PASSWORD_LENGTH)
    .matches(PASSWORD_REGEX, ' '),
});

export const usernameSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .matches(USERNAME_REGEX, 'Invalid username')
    .max(MAX_USERNAME_LENGTH)
    .required('Please enter your username'),
});

export const verifySchema = Yup.object({
  otp: Yup.string()
    .matches(INTEGER_REGEX, 'Only numbers are allowed')
    .required('OTP is required'),
});
export type VerifyFormData = Yup.InferType<typeof verifySchema>;

export const loginWithPasswordSchema = emailSchema.concat(passwordSchema);

export type LoginWithPasswordData = Yup.InferType<
  typeof loginWithPasswordSchema
>;

export const resetPasswordSchema = passwordSchema.concat(
  confirmedPasswordSchema
);
export type ResetPasswordData = Yup.InferType<typeof resetPasswordSchema>;


export const referralCodeSchema = Yup.object().shape({
  referralCode: Yup.string().trim()
});
