import { ErrorResponse, UserLoginResponse, UserLoginWithGGData } from '@interfaces';
import { useMutation } from '@tanstack/react-query';

import { userAuthApi } from '../../userAuthApi';

export interface MutationParams {
  onSuccess: (res: UserLoginResponse) => void;
  onError?: (error: ErrorResponse | Error) => void;
}

const useUserLoginWithGGMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: UserLoginWithGGData) =>
      userAuthApi.client.userLoginWithGG(data),
    onSuccess,
    onError,
  });
};

export default useUserLoginWithGGMutation;
