import { twMerge } from 'tailwind-merge';

import Button, { BUTTON_VARIANT } from '@components/button';
import DividerFullWidth from '@components/dividerFullWidth';

import { IModalFooterProps } from './types';

const ModalFooter = ({
  cancelButtonProps,
  confirmButtonProps,
  hideDivider,
  onClose,
  customCancelButtonElement,
}: IModalFooterProps) => {
  const {
    text: cancelText,
    onClick: customCancelOnClick,
    isDisabled: cancelIsDisabled,
  } = cancelButtonProps || {};

  const {
    text: confirmText,
    onClick: confirmOnClick,
    isDisabled: confirmIsDisabled,
    type: confirmType,
  } = confirmButtonProps || {};

  const renderCancelButton = () => {
    if (customCancelButtonElement) {
      return customCancelButtonElement;
    }

    return (
      <Button
        form='none'
        variant={BUTTON_VARIANT.SECONDARY}
        onClick={(e) => {
          e.preventDefault();

          customCancelOnClick?.() || onClose?.();
        }}
        disabled={cancelIsDisabled}
      >
        {cancelText || 'Cancel'}
      </Button>
    );
  };

  return (
    <div className="mt-6 space-y-6">
      {!hideDivider && <DividerFullWidth />}
      <div className="flex justify-between">
        {(onClose || cancelButtonProps || customCancelButtonElement) &&
          renderCancelButton()}
        {confirmButtonProps && (
          <Button
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={() => {
              if (confirmIsDisabled) return;
              confirmOnClick?.();
            }}
            className={twMerge(!cancelButtonProps && 'ml-auto')}
            disabled={confirmIsDisabled}
            type={confirmType}
          >
            {confirmText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModalFooter;
