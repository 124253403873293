
import Link from 'next/link';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Button, { BUTTON_VARIANT } from '@components/button';
import DividerFullWidth from '@components/dividerFullWidth';
import HookFormInput from '@components/form/hookFormInput';
import {
  PasswordInput,
  PasswordInputWithValidation,
} from '@components/form/passwordInput';

import {
  MAX_EMAIL_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_USERNAME_LENGTH,
} from '@shared/constants';
import { AUTH_ERROR_CODES } from '@shared/constants/errors';
import useReCaptchaToken from '@shared/hooks/useReCaptchaToken';
import { toastError, USER_LOGIN } from '@shared/utils';

import { RegisterUserFormData, registerUserSchema } from './registerFormSchema';
import useUserRegisterMutation from '@api/user/auth/mutations/register/useUserRegisterMutation';
import { ICreateAccountProps } from './CreateAccount';

export const RegisterForm = (props: ICreateAccountProps) => {

  const {
    disabled,
    onCreateAccount,
    setRegistrationEmail,
    backToLogin
  } = props;

  const methods = useForm<RegisterUserFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(registerUserSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = methods;

  const getReCaptchaToken = useReCaptchaToken();

  const {
    mutate: userRegisterMutation,
    isPending: isSubmittingRegistration,
  } = useUserRegisterMutation({
    onSuccess: () => onCreateAccount(),
    onError: (e) => {
      const message = e.message.toLowerCase();


      if (message.includes('email must be an email'))
        return setError('email', {
          type: 'manual',
          message: "Email must be an email",
        });
      else if (message.includes('email'))
        return setError('email', {
          type: 'manual',
          message: e.message,
        });
      else if (message.includes('username already exists')) {
        return setError('username', {
          type: 'manual',
          message: e.message,
        });
      }

      if (e instanceof Error) {
        toastError(AUTH_ERROR_CODES.registration[e.message] || e.message);
      }
    },
  });

  const onSubmit: SubmitHandler<RegisterUserFormData> = async (values) => {
    const gReCaptchaToken = await getReCaptchaToken();
    userRegisterMutation({ ...values, recaptchaToken: gReCaptchaToken });
    setRegistrationEmail(values.email);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <HookFormInput
          label="EMAIL ADDRESS"
          id="email"
          register={register('email')}
          error={errors['email']?.message}
          required
          type="text"
          autoResize
          maxLength={MAX_EMAIL_LENGTH}
        />
        <HookFormInput
          label="USERNAME"
          id="username"
          register={register('username')}
          error={errors['username']?.message}
          required
          type="text"
          className="mt-4"
          autoResize
          maxLength={MAX_USERNAME_LENGTH}
        />
        <PasswordInputWithValidation
          label="Password"
          id="password"
          register={register('password')}
          error={errors['password']?.message}
          disabled={disabled}
          required
          autoresize
          className="mt-4"
          type="password"
          maxLength={MAX_PASSWORD_LENGTH}
        />
        <PasswordInput
          label="Confirm your password"
          id="confirmedPassword"
          error={errors['confirmedPassword']?.message}
          register={register('confirmedPassword')}
          disabled={disabled}
          required
          className="mt-4"
          autoResize
          maxLength={MAX_PASSWORD_LENGTH}
        />

        <HookFormInput
          label="Referral code"
          id="referralCode"
          register={register('referralCode')}
          error={errors['referralCode']?.message}
          hideIsOptional={false}
          type="text"
          className="mt-4"
          autoResize
        />
        <DividerFullWidth className="my-6" />

        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <p className="text-neutral-400 font-small">
              Already have AI TECH account?
            </p>
            {
              backToLogin ? (

                <Button
                  className="w-fit p-0"
                  variant={BUTTON_VARIANT.LINK}
                  onClick={backToLogin}
                >
                  Back to login
                </Button>
              ) : (
                <Link
                  href={USER_LOGIN}
                  className="font-xsmall w-fit cursor-pointer font-bold uppercase text-yellow500"
                >
                  Login
                </Link>
              )
            }
          </div>
          <Button
            className="authButton"
            type="submit"
            disabled={isSubmittingRegistration}
          >
            Next
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}