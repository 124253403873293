import * as Yup from 'yup';

import { PASSWORD_REGEX } from '@components/form/passwordInput/regex';

import { DEFAULT_VERSION_NAME } from '@features/publisher/appCreation/constants';

import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '@shared/inputLength';

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .trim()
    .required('Please enter your password')
    .min(
      MIN_PASSWORD_LENGTH,
      `Minimum number of characters is ${MIN_PASSWORD_LENGTH}`
    )
    .max(
      MAX_PASSWORD_LENGTH,
      `Minimum number of characters is ${MAX_PASSWORD_LENGTH}`
    )
    .matches(PASSWORD_REGEX, 'Please enter a valid password'),
  newPassword: Yup.string()
    .trim()
    .required('Please enter your password')
    .min(
      MIN_PASSWORD_LENGTH,
      `Minimum number of characters is ${MIN_PASSWORD_LENGTH}`
    )
    .max(
      MAX_PASSWORD_LENGTH,
      `Minimum number of characters is ${MAX_PASSWORD_LENGTH}`
    )
    .matches(PASSWORD_REGEX, 'Please enter a valid password')
    .test(
      'different-from-old-password',
      'The new password you entered is the same as your previous password. Please choose another password and try again',
      (value, context) => {
        const { oldPassword } = context.parent;
        return value && oldPassword ? value !== oldPassword : true;
      }
    ),
  confirmedPassword: Yup.string()
    .trim()
    .test(
      'password-confirmed',
      'The new password and confirm password you entered do not match. Please try again',
      (value, context) => value === context.parent.newPassword
    )
    .required('Please enter your password')
    .min(
      MIN_PASSWORD_LENGTH,
      `Minimum number of characters is ${MIN_PASSWORD_LENGTH}`
    )
    .max(
      MAX_PASSWORD_LENGTH,
      `Minimum number of characters is ${MAX_PASSWORD_LENGTH}`
    )
    .matches(PASSWORD_REGEX, 'Please enter a valid password'),
});

export type ChangePasswordFormData = Yup.InferType<typeof changePasswordSchema>;

export const changeVersionNameSchema = Yup.object({
  versionName: Yup.string()
    .max(10, "Version name can't be more than 10 characters")
    .transform((value, originalValue) => {
      if (originalValue?.endsWith('.')) {
        return originalValue.slice(0, -1);
      }

      return value;
    })
    .transform((value) => {
      if (!value) {
        return DEFAULT_VERSION_NAME;
      }

      return value;
    })
    .matches(/^[A-Za-z0-9\s.]+$/, 'Invalid version name'),
});

export type ChangeVersionNameFormData = Yup.InferType<
  typeof changeVersionNameSchema
>;
