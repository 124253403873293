import { ErrorResponse, UserRegisterData } from '@interfaces';
import { useMutation } from '@tanstack/react-query';

import { userAuthApi } from '../../userAuthApi';

export interface MutationParams {
  onSuccess: () => void;
  onError?: (error: ErrorResponse | Error) => void;
}

const useUserRegisterMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: UserRegisterData) =>
      userAuthApi.client.userRegister(data),
    onSuccess,
    onError,
  });
};

export default useUserRegisterMutation;
