import Button from "@components/button"
import DividerFullWidth from "@components/dividerFullWidth"
import HookFormInput from "@components/form/hookFormInput"
import { MAX_USERNAME_LENGTH } from "@shared/constants"
import { FormProvider, useForm } from "react-hook-form"
import { RegisterWithGGFormData, registerWithGoogleSchema } from "../registration/createAccount/registerFormSchema"
import { yupResolver } from "@hookform/resolvers/yup"
import useUserRegisterWithGGMutation from "@api/user/auth/mutations/register/useUserRegisterWithGGMutation"
import { toastError, toastSuccess } from "@shared/utils"
import { IUserGGInfo } from "./LoginForm"
import { setAccessTokenForCurrentUser, setRefreshTokenForCurrentUser } from "@shared/utils/cookies/userCookies-client"

export const RegisterWithGoogle = ({ userGGInfo }: { userGGInfo: IUserGGInfo | null }) => {
  const methods = useForm<RegisterWithGGFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(registerWithGoogleSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = methods;

  const {
    mutate: userRegisterWithGGMutation,
    isPending: isSubmittingRegistration,
  } = useUserRegisterWithGGMutation({
    onSuccess: (data) => {
      setAccessTokenForCurrentUser(data.data.token);
      setRefreshTokenForCurrentUser(data.data.refreshToken);
      toastSuccess('Register successfully', { description: 'Start exploring' });
      window.location.reload();
    },
    onError: (e) => {
      const message = e.message.toLowerCase();
      if (message.includes('username'))
        return setError('username', {
          type: 'manual',
          message: e.message,
        });

      if (e instanceof Error) {
        toastError(e.message);
      }
    },
  });

  const onSubmit = async (data: RegisterWithGGFormData) => {
    if (!userGGInfo) {
      toastError("Something went wrong. Please try again.");
      return;
    }
    userRegisterWithGGMutation({
      ...data,
      email: userGGInfo.email,
      googleAccessToken: userGGInfo.googleAccessToken,
    });

  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <HookFormInput
          label="USERNAME"
          id="username"
          register={register('username')}
          error={errors['username']?.message}
          required
          type="text"
          className="mt-4"
          autoResize
          maxLength={MAX_USERNAME_LENGTH}
        />

        <HookFormInput
          label="Referral code"
          id="referralCode"
          register={register('referralCode')}
          error={errors['referralCode']?.message}
          hideIsOptional={false}
          type="text"
          className="mt-4"
          autoResize
        />
        <DividerFullWidth className="my-6" />
        <div className="flex justify-center items-center">
          <Button
            type="submit"
            disabled={isSubmittingRegistration}
          >
            complete
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}