import { ErrorResponse, UserLoginResponse, UserRegisterWithGGData } from '@interfaces';
import { useMutation } from '@tanstack/react-query';

import { userAuthApi } from '../../userAuthApi';

export interface MutationParams {
  onSuccess: (res: UserLoginResponse) => void;
  onError?: (error: ErrorResponse | Error) => void;
}

const useUserRegisterWithGGMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: UserRegisterWithGGData) =>
      userAuthApi.client.userRegisterWithGG(data),
    onSuccess,
    onError,
  });
};

export default useUserRegisterWithGGMutation;
