import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { IEmailInputProps } from "./EmailInput"
import { EmailFormData, emailSchema } from "@shared/schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormInput from "@components/form/hookFormInput";
import DividerFullWidth from "@components/dividerFullWidth";
import Link from "next/link";
import Button, { BUTTON_VARIANT } from "@components/button";

export const EmailInputForm = (props: IEmailInputProps) => {

  const {
    isSubmitting,
    loginRoute,
    handleSubmitEmailMutation,
    backToLogin,
  } = props;

  const methods = useForm<EmailFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(emailSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods;

  const onSubmit: SubmitHandler<EmailFormData> = async (data) => {
    handleSubmitEmailMutation(data.email);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <HookFormInput
          label="EMAIL ADDRESS"
          id="email"
          register={register('email')}
          error={errors['email']?.message}
          required
          type="text"
          autoResize
        />
        <DividerFullWidth className="my-6" />
        <div className="flex items-center justify-between">
          {
            backToLogin ? (
              <Button
                className="w-fit p-0"
                variant={BUTTON_VARIANT.LINK}
                onClick={backToLogin}
              >
                Back to login
              </Button>
            ) : (
              <Link
                href={loginRoute}
                className="font-xsmall cursor-pointer font-bold uppercase text-yellow500"
              >
                Back to login
              </Link>
            )
          }
          <Button className="authButton" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}