import useUserLoginWithGGMutation from "@api/user/auth/mutations/register/useUserLoginWithGGMutation";
import { ILoginProps } from "@components/auth/login/Login";
import Button, { BUTTON_VARIANT } from "@components/button";
import DividerFullWidth from "@components/dividerFullWidth";
import HookFormInput from "@components/form/hookFormInput";
import { PasswordInput } from "@components/form/passwordInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleIcon } from "@shared/assets";
import { MAX_EMAIL_LENGTH, MAX_PASSWORD_LENGTH } from "@shared/constants";
import { LoginWithPasswordData, loginWithPasswordSchema } from "@shared/schemas";
import { toastError, toastSuccess, USER_REGISTER } from "@shared/utils";
import { setAccessTokenForCurrentUser, setRefreshTokenForCurrentUser } from "@shared/utils/cookies/userCookies-client";
import Link from "next/link";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

export interface IUserGGInfo {
  email: string;
  name: string;
  picture: string;
  // and some other fields
  googleAccessToken: string;
}

type ILoginFormProps = Omit<ILoginProps, 'forgotPasswordRoute'> & {
  forgotPasswordRoute?: string;
  setUserGGInfo?: (data: IUserGGInfo) => void;
  onForgotClick?: () => void;
  onSignUpClick?: () => void;
  onContinueWithGoogle?: () => void;
};

export const LoginForm = (props: ILoginFormProps) => {
  const {
    handleLoginOtpMutation,
    error,
    isSubmitting,
    forgotPasswordRoute,
    onForgotClick,
    onSignUpClick,
    onContinueWithGoogle,
    setUserGGInfo,
  } = props;

  const methods = useForm<LoginWithPasswordData>({
    mode: 'onSubmit',
    resolver: yupResolver(loginWithPasswordSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = methods;

  const {
    mutate: userLoginWithGGMutation,
    isPending: isSubmittingLoginWithGG,
  } = useUserLoginWithGGMutation({
    onSuccess: ({
      data: {
        token,
        refreshToken,
      },
    }) => {
      setAccessTokenForCurrentUser(token);
      setRefreshTokenForCurrentUser(refreshToken);
      toastSuccess('Login successfully', { description: 'Start exploring' });
      window.location.reload();
    },
    onError: (e) => {
      if (e.message.toLowerCase().includes('not found user')) {
        onContinueWithGoogle?.(); // if haven't registered, continue with google
      } else {
        toastError(e.message);
      }
    },
  });

  const onSubmit: SubmitHandler<LoginWithPasswordData> = (values) => {
    handleLoginOtpMutation({
      email: values.email,
      password: values.password,
    });
  };

  useEffect(() => {
    if (error) {
      if (error.message.includes('password'))
        setError('password', {
          type: 'manual',
          message: error.message,
        });
      else if (error.message.includes('email')) {
        setError('email', {
          type: 'manual',
          message: error.message,
        });
      }
    }
  }, [error, setError]);

  const onLoginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // get response.access_token and call BE to get userInfo
      try {
        const response = await fetch(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `${tokenResponse.token_type} ${tokenResponse.access_token}`,
            },
          }
        );

        const userInfo = await response.json();
        setUserGGInfo?.({
          ...userInfo,
          googleAccessToken: tokenResponse.access_token,
        });
        if (userInfo) {
          userLoginWithGGMutation({
            email: userInfo.email,
            googleAccessToken: tokenResponse.access_token,
          });
        }
      } catch (error) {
        toastError('Login with Google failed');
      }
    },
    onError: (error) => {
      toastError(error.error_description);
    },
  });

  return (
    <FormProvider {...methods}>
      <form
        className="m-auto w-full max-w-37.5rem rounded bg-neutral800"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        id="login-form"
      >
        <HookFormInput
          label="EMAIL"
          id="email"
          register={register('email')}
          error={errors['email']?.message}
          required
          type="text"
          autoResize
          maxLength={MAX_EMAIL_LENGTH}
        />
        <PasswordInput
          label="PASSWORD"
          id="password"
          autoResize
          register={register('password')}
          className="mt-4"
          error={errors['password']?.message}
          required
          maxLength={MAX_PASSWORD_LENGTH}
          {
          ...(
            onForgotClick && {
              buttonText: "Forgot password?",
              onButtonTextClick: onForgotClick
            }
          )
          }
          {
          ...(
            forgotPasswordRoute && {
              linkHref: forgotPasswordRoute,
              linkText: "Forgot password?"
            }
          )
          }
        />
        <div className="flex items-center gap-2">
          <DividerFullWidth className="my-6" />
          or
          <DividerFullWidth className="my-6" />
        </div>

        <Button
          form="login-with-google"
          className="w-full"
          variant={BUTTON_VARIANT.SECONDARY}
          disabled={isSubmittingLoginWithGG}
          onClick={() => onLoginWithGoogle()}
        >
          <div className="flex items-center gap-2">
            <GoogleIcon />
            Login with Google
          </div>
        </Button>

        <DividerFullWidth className="my-6" />

        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-neutral-400 font-small text-neutral400">
              Don&rsquo;t have account?
            </p>
            {
              onSignUpClick ? (
                <Button
                  className="w-fit p-0"
                  variant={BUTTON_VARIANT.LINK}
                  form="register-form"
                  onClick={onSignUpClick}
                >
                  Sign up
                </Button>
              ) : (
                <Link
                  href={USER_REGISTER}
                  className="font-xsmall w-fit cursor-pointer font-bold uppercase text-yellow500"
                >
                  Sign up
                </Link>
              )
            }
          </div>
          <Button form="login-form" className="authButton" disabled={isSubmitting}>
            Login
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}