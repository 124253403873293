import {
  ErrorResponse,
  UserConfirmEmailResponse,
  UserRequestOtpData,
} from '@interfaces';
import { useMutation } from '@tanstack/react-query';
import { userAuthApi } from '../../userAuthApi';

interface MutationParams {
  onSuccess: (response: UserConfirmEmailResponse) => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useUserRequestResendOtpMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: UserRequestOtpData) =>
      userAuthApi.client.userRequestResendOtp(data),
    onSuccess,
    onError,
  });
};

export default useUserRequestResendOtpMutation;
