import {
  ErrorResponse,
  UserConfirmEmailData,
  UserConfirmEmailResponse,
} from '@interfaces';
import { useMutation } from '@tanstack/react-query';
import { userAuthApi } from '../../userAuthApi';

interface MutationParams {
  onSuccess: (response: UserConfirmEmailResponse) => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useUserRegisterConfirmMailMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: UserConfirmEmailData) =>
      userAuthApi.client.userRegisterConfirmEmail(data),
    onSuccess,
    onError,
  });
};

export default useUserRegisterConfirmMailMutation;
