import { ErrorResponse, UserLoginOtpData } from '@interfaces';
import { useMutation } from '@tanstack/react-query';
import { userAuthApi } from '../../userAuthApi';


interface MutationParams {
  onSuccess: () => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useUserLoginOtpMutation = ({
  onSuccess,
  onError,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: UserLoginOtpData) =>
      userAuthApi.client.loginOtp(data),
    onSuccess,
    onError,
  });
};

export default useUserLoginOtpMutation;
